* {
  outline: none;

  &:focus {outline: none;}
}

img {
  vertical-align: top;
  border: 0;
  max-width: 100%;
  height: auto;
}

header,
footer,
article,
section,
hgroup,
nav,
figure {
  display: block;
}

form,
fieldset,
button {
  margin: 0;
  padding: 0;
  border: none;
}

button {
  background-color: transparent;
  -webkit-appearance: none;
}

input {
  box-sizing: border-box;
  border-radius: 0;
  font: 16px $primary-font-family;
  font-weight: 400;
  -webkit-appearance: none;

  &[type=text],
  &[type=tel],
  &[type=email],
  &[type=password] {
    &.error {border-bottom: 1px solid #ec1b35;}
  }

  &[type="submit"] {
    border-radius: 0;
  }

  @include placeholder {
    color: #fff;
    opacity: 1;
    text-transform: uppercase;
  }

  &:focus {
    @include placeholder {opacity: 0.5;}
  }
}

textarea {
  font: 16px $primary-font-family;

  @include placeholder {
    color: #fff;
    opacity: 1;
    text-transform: uppercase;
  }

  &:focus {
    border: 1px solid #00adef;

    @include placeholder {opacity: 0.5;}
  }

  &.error {border-bottom: 1px solid #ec1b35;}
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  outline: none;
  border: none;
}

html {
  font-size: 62.5%;
  height: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  margin: 0;
  font: 16px/1.875 $primary-font-family;
  font-weight: 300;
  text-size-adjust: 100%;
  overflow-x: hidden;

  &.has-overlay {
    #overlay {
      pointer-events: auto;
      opacity: 1;
    }
  }
}
a{
  color: $primary-link-color;
  text-decoration: none;
}
.wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100%;
  min-height: 100vh;
  overflow: hidden;
}
#main {
  flex: 1 0 auto;
}
.holder{
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 42px;
}
#header{
  width: 100%;
  height: 82px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: $header-index;

  &.sub-menu-opened{
    background: #fff;

    &:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background: #c7c7c7;
      top: 0;
      left: 0;
    }

    .logo{
      background: url("../images/logo-black.png") no-repeat;
    }

    #main-nav > ul > li > a{color: #1b1b1b;}

    .side-nav{
      ul{
        li{
          &.get-demo a{
            border: 1px solid $blue;
            background: $blue;
            color: #fff;
            transition: background-color 0.2s;
            &:hover {
              background-color: $hover-link;
            }
            &:active {
              background-color: $active-link;
            }
          }
        }

        a{
          color: #1b1b1b;
        }
      }
    }
  }
}
.logo{
  float: left;
  background: url("../images/logo.png") no-repeat;
  width: 182px;
  height: 41px;
  text-indent: -9999px;
  overflow: hidden;
  margin: 20px 27px 0 0;
}
.logo a{
  display: block;
  height: 100%;
}
#main-nav{
  > ul{
    margin: 0;
    padding: 32px 0 0 0;
    list-style: none;
    text-align: center;
    font-weight: 500;
    font-size: 12px;

    > li{
      display: inline-block;
      padding: 0 10px 0 9px;

      &.active {
        .drop-down {
          display: block;
        }

        > a {
          border-bottom: 3px solid #006ce5;
          &:hover {
            border-color: #006ce5;
          }
        }
      }

      > a{
        padding: 0 5px 5px;
        display: inline-block;
        color: #fff;
        text-transform: uppercase;

        &:hover{
          border-bottom: 3px solid #66baff;
        }
      }

      // &:first-child a{border-bottom-color: #66baff;}

    }
  }

  .drop-down{
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 82px;
    z-index: $header-index;
    background: #f9f9f9;
    line-height: 1.75;
    padding: 29px 0 46px;
    border: 1px solid #c7c7c7;
    border-width: 1px 0;
    text-align: left;

    // &.opened{
    //  display: block;
    // }

    .holder{
      overflow: hidden;
    }

    .drop-info-block{
      width: 23.12%;
      float: left;
      box-sizing: border-box;
      padding: 0 4.276% 0 2px;
      color: #878787;
      letter-spacing: -0.5px;

      .title{
        display: block;
        font-size: 16px;
        margin: 0 0 14px;
        color: #FFF;
      }

      p{
        margin: 0 0 8px;
      }
    }

    .column{
      float: left;
      width: 25.54%;
      box-sizing: border-box;
      padding: 4px 20px 0 0;
      letter-spacing: -0.5px;

      .title{
        min-height: 28px;
        margin: 0 0 8px;
        color: #aeaeae;

        strong{
          text-transform: uppercase;
          font-weight: 500;
        }
      }

      ul{
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 14px;
        letter-spacing: -0.5px;

        li{
          padding: 0 0 4px 18px;
          position: relative;

          &:before{
            content: "";
            position: absolute;
            left: 2px;
            top: 7px;
            background: url("../images/arrow-2.png") no-repeat;
            width: 6px;
            height: 10px;
          }
        }

        a{
          color: #404040;
        }
      }
    }

    .banner{
      width: 25.63%;
      height: 212px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #efefef;
      text-align: center;
      font-size: 15px;
      margin: 4px 0 0 1px;
      letter-spacing: -0.5px;
      line-height: 1.667;

      .banner-holder{
        width: 100%;
      }

      p{
        margin: 0 0 28px;
      }
    }

    // .integrations-logos{
    //   margin: 0;
    //   padding: 2px 0 0;
    //   list-style: none;
    //   overflow: hidden;

    //   li{
    //     margin: 0 0 2px 1px;
    //     width: calc(20% - 1px);
    //     height: 67px;
    //     float: left;
    //   }

    //   a{
    //     width: 100%;
    //     height: 100%;
    //     position: relative;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     background: #f0f0f0;

    //     &:before{
    //       content: "";
    //       position: absolute;
    //       width: 100%;
    //       height: 100%;
    //       background: #f0f0f0;
    //       transition: all 0.2s ease-in-out;
    //     }

    //     &:hover:before{
    //       opacity: 0;
    //     }

    //     img{
    //       position: relative;
    //       z-index: 10;
    //     }
    //   }
    // }
    .btn-more{
      font-size: 12px;
      font-weight: 500;
    }
  }

  .mobile-toggle {
    display: none;
    width: 17px;
    height: 14px;
    background-image: url('../images/mobile-menu.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.btn-more {
  position: relative;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  transition: color 0.2s;
  &:hover {
    color: $hover-link;
    &::before {
      opacity: 1;
    }
    path {
      fill: $hover-link;
      stroke: $hover-link;
    }
  }
  &:active {
    color: $active-link;
    &::before {
      opacity: 1;
    }
    path {
      fill: $active-link;
      stroke: $active-link;
    }
  }

  &::before {
    position: absolute;
    left: 0;
    bottom: -1px;
    content: '';
    width: calc(100% - 23px);
    height: 2px;
    background-color: currentColor;
    opacity: 0;
    transition: opacity 0.2s;
  }

  svg {
    position: relative;
    top: 1px;
    width: 13px;
    height: 11px;
    margin-left: 7px;
    path {
      transition: fill 0.2s, stroke 0.2s;
    }
  }

  // &:after{
  //   content: "";
  //   background: url("../images/arrow.png") no-repeat;
  //   width: 13px;
  //   height: 11px;
  //   display: inline-block;
  //   vertical-align: middle;
  //   margin: -2px 0 0 7px;
  // }
}
.btn{
  background: $blue;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 25px;
  line-height: 44px;
  padding: 0 31px;
  display: inline-block;
  font-weight: 400;
  letter-spacing: normal;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;
  &:hover {
    background-color: $hover-link !important;
    border-color: $hover-link !important;
  }
  &:active {
    background-color: $active-link !important;
    border-color: $active-link !important;
  }

  &.white{
    background: #fff;
    color: #1b1b1b;
    font-weight: 600;
    &:hover {
      color: $blue !important;
      background-color: #fff !important;
    }
    &:active {
      background-color: $light-blue !important;
    }

  }

  &.transparent,
  &.border-btn {
    background-color: transparent;
    color: $blue;
    &:hover {
      color: #fff !important;
      background-color: $hover-link !important;
      border-color: $hover-link !important;
    }
    &:active {
      background-color: $active-link !important;
      border-color: $active-link !important;
    }
  }
}

.integrations-logos{
  margin: 0;
  padding: 2px 0 0;
  list-style: none;
  overflow: hidden;

  li{
    margin: 0 0 2px 1px;
    width: calc(20% - 1px);
    height: 67px;
    float: left;
  }

  a{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f0f0;

    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #f0f0f0;
      transition: all 0.2s ease-in-out;
    }

    &:hover:before{
      opacity: 0;
    }

    img{
      position: relative;
      z-index: 10;
    }
  }
}


.side-nav{
  float: right;
  ul{
    margin: 24px 3px 0 0;
    padding: 0;
    list-style: none;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    overflow: hidden;

    li{
      float: left;
      margin: 0 0 0 9px;

      &.get-demo a{
        border: 1px solid #fff;
        border-radius: 17px;
      }
    }

    a{
      color: #fff;
      display: inline-block;
      line-height: 34px;
      padding: 0 16px;
    }
  }
}


.top-section{
  padding: 80px 0 0;
  background-size: cover;
  color: #fff;
  &.horizontal-alignment {
    img {
      width: 100%
    }
    .holder {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: none;
      margin-left: 300px;
    }

    .text-block {
      max-width: 370px;
      width: 100%;
      padding: 0;
      text-align: left;
    }

    .image-holder {
      width: calc(100% - 450px);
    }

    h1 {
      margin-bottom: 12px;
      line-height: 1.375;
    }

    .btn {
      margin: 0;
    }
  }

  &.headline {
    align-items: flex-start;
    &.has-media {
      height: 400px;
      margin-bottom: 23%;
    }
    .image-holder {
      margin-top: -20%;
      margin-bottom: 23%;
      transform: translateY(46%);
    }
  }

  .text-block{
    text-align: center;
    padding: 15px 0 0;
    font-size: 18px;
  }

  h1{
    font-size: 38px;
    font-weight: 400;
    margin: 0 0 4px;
  }

  p{
    margin: 0 0 29px;
  }

  img{
    margin: 0 auto;
    display: block;
  }

  .btn{
    line-height: 50px;
    border-radius: 25px;
    padding: 0 36px;
    margin: 0 0 1px;
  }
}

.content-section{
  h2 {
    text-align: center;
    color: $navy-blue;
    margin: 0 0 60px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.475;
  }
}

.partners-logos{
  border-bottom: 1px solid #dcdcdc;
  // margin: 0 0 62px;
  padding: 34px 0 31px;

  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    li{
      display: inline-block;
      vertical-align: middle;
    }

    img{vertical-align: middle;}
  }
}

.icon-blocks-holder{
  text-align: center;
  // margin: 0 0 90px;
  padding: 62px 0 90px;

  h2 {
    margin-bottom: 0;
  }

  h3,
  p {
    margin: 0;
  }

  h3 {
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: 600;
  }

  p {
    width: 82%;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.5;
  }


  .blocks-wrap{
    margin-bottom: -19px;
    padding: 63px 0px 39px;
    letter-spacing: -3px;
    word-spacing: -3px;
  }

  .icon-block{
    display: inline-block;
    width: 25%;
    margin-bottom: 19px;
    word-spacing: 0;
    letter-spacing: 0;
    vertical-align: top;
  }

  .ico-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 20px;
  }
}

.case-studies-slider {
  position: relative;
  // margin: 0 0 92px;
  &.disable-pagination {
    .bx-controls {
      display: none;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    height: 100%;
  }

  .block-holder {
    display: flex;
    align-items: stretch;
  }

  .case-study-description {
    display: block;
    width: 50%;
    padding: 95px 85px 95px 55px;
    color: #fff;
    // box-sizing: border-box;

    .company-logo{
      display: block;
      margin: 0 0 13px;
    }

    blockquote{
      display: block;
      width: 100%;
      margin: 0 0 27px;
      padding-left: 4px;
      line-height: 2.05;
      letter-spacing: 0.75px;

      q{
        display: block;
        margin: 0 0 10px;
        font-weight: 300;
        font-style: italic;

        // &:before,
        // &:after{
        //   content: '"';
        //   quotes: none;
        // }
      }

      cite {
        font-weight: 600;
        display: block;
        font-style: normal;
        font-size: 18px;
      }
    }

    .btn{
      height: 44px;
      // margin-left: 12px;
      padding: 0 22px;
      background: none;
      border: 1px solid #fff;
      text-transform: capitalize;
    }
  }
  .case-study-image{
    width: 50%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .bx-controls-direction{
    display: none;
  }
  .bx-pager{
    position: absolute;
    left: 60px;
    bottom: 60px;
    z-index: $bx-index;

    .bx-pager-item{
      float: left;
      margin: 0 6px 0 0;

      a{
        display: block;
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        border: 1px solid #fff;
        border-radius: 50%;
        text-indent: -9999px;

        &.active{
          background: #fff;
        }
      }
    }
  }
}

.image-block{
  // margin: 0 0 102px;
  padding: 91px 0 102px;

  h2 {
    margin-bottom: 68px;
  }

  .image-box {
    margin-bottom: 41px;
  }

  img{
    display: block;
    margin: 0 auto;
  }
}

.swag-zone{
  overflow: hidden;
  // margin: 0 0 91px;
  display: flex;
  align-items: stretch;

  .banner-holder{
    width: 25.09%;
    float: left;
    padding: 66px 0 40px;

    .frame{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .btn{
      display: block;
      width: 172px;
      height: 46px;
      margin: 19px 0 0;
      font-size: 12px;
      line-height: 40px;
      border: 2px solid #fff;
      border-radius: 25px;
    }
  }
  .image-holder{
    overflow: hidden;
  }
}
.testimonials-holder{
  text-align: center;
  // margin-bottom: 97px;
  padding: 91px 0 97px;

  h2{
    margin: 0 0 9px;
  }

  .blocks-wrap{
    letter-spacing: -3px;
    word-spacing: -3px;
    margin: 0 -30px;
  }

  .testimonial-block{
    width: 33.33%;
    display: inline-block;
    margin-top: 37px;
    padding: 0 30px;
    letter-spacing: 0;
    word-spacing: 0;
    vertical-align: top;

    .logo-holder{
      margin: 0 0 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    blockquote{
      margin: 0;

      q{
        display: block;
        word-spacing: -1px;
        margin: 0 0 21px;
        max-height: 96px;
        font-weight: 300;
        line-height: 1.5;
        letter-spacing: -0.2px;
        overflow: hidden;
      }

      cite{
        display: block;
        text-align: center;
        font-style: normal;
        font-size: 15px;

        strong{
          display: block;
          font-weight: 700;
          line-height: 1.3;
        }
      }
    }
  }
}


.resources-section{
  background: #f5f5f5;
  padding: 64px 0 69px;
  // margin: 0 0 97px;
  &.partners {
    background-color: #fff;
  }

  h2 {
    margin: 0 0 42px;
    text-align: center;
    color: #0f2942;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.475;
  }

  .holder{
    max-width: 1022px;
    padding: 0 40px;
  }
}

.resources-blocks-holder {
  // overflow: hidden;
  &::after {
    content: '';
    display: table;
    clear: both;
  }
  .single-img {
    .image-holder {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
        height: auto;
      }
    }
  }
}

.resources-block {
  display: inline-block;
  max-width: 294px;
  width: 100%;
  margin-right: 25px;
  margin-bottom: 30px;
  font-size: 13px;
  color: #505050;
  text-align: center;
  &.video {
    .image-holder {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../images/video_play_icon_small.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 48px;
      }
    }
  }
  &:nth-of-type(3n) {
    margin-right: 0;
  }

  a {
    display: block;
    color: #505050;
    border-radius: 0 0 4px 4px;
    transition: box-shadow 0.15s, background-color 0.2s;
    &:hover {
      box-shadow: 0 1px 12px rgba(0,0,0,0.15);
    }
    &:active {
      box-shadow: none;
      background-color: #f5f5f5;
      .text-holder {
        margin: 0;
        padding-left: 19px;
        padding-right: 19px;
        border-color: #c7c7c7;
      }
    }
  }

  .frame{
    background: #fff;
    border-radius: 0 0 4px 4px;
  }

  .image-holder{
    height: 178px;

    img{
      width: 100%;
      height: 100%;
    }
  }

  .text-holder {
    margin: 0 -1px;
    padding: 27px 20px 22px;
    border: 1px solid #efefef;
    border-top: 0;
    border-radius: 0 0 4px 4px;
  }

  h3{
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 7px;
  }
  p{
    margin: 0 0 15px;
    line-height: 1.65;
  }
  .type{
    display: block;
    text-transform: uppercase;
    color: #058ee2;
  }
}

.request-demo-block{
  text-align: center;
  color: #878787;

  h2{
    margin: 0 0 9px;
  }
  p{
    margin: 0 0 29px;
  }

  .buttons-holder{
    margin: 0 0 25px;

    span{
      margin: 0 11px 0 13px;
    }

    .btn{
      width: 170px;
      padding: 0 10px;
      font-size: 13px;
      font-weight: 600;
      border: 2px solid $blue;
    }

    .btn.border-btn{
      // width: 172px;
      color: $blue;
      // border-color: $blue;
      background: none;
    }
  }
}
.has-radial-bg{
  position: relative;
  padding: 97px 0 0;

  &:before{
    content: "";
    width: 100%;
    height: 235px;
    background: #fff;
    clip-path: ellipse(80% 48% at 50% 50%);
    position: absolute;
    bottom: 199px;
    left: 0;
    z-index: 2;
  }

  &:after{
    content: "";
    background: #0042d3;
    width: 100%;
    height: 250px;
    left: 0;
    bottom: 0;
    position: absolute;
  }
  .holder{
    position: relative;
    z-index:20;
  }
}
#footer{
  background: #121d25;
  color: #ddd;

  a{
    color: #fff;
  }

  .top-footer{
    overflow: hidden;
    max-width: 926px;
    margin: 0 auto;
    padding: 46px 0 82px;
    font-size: 13px;

    .columns-holder{
      overflow: hidden;

      .column{
        float: left;
        width: 25%;
        // padding: 0 26px 0 0;
        padding-left: 19px;
        &:first-of-type {
          padding-left: 13px;
        }
      }
    }

    h4{
      margin: 0 0 10px;
      font-size: 14px;
      font-weight: 500;
    }
    ul{
      margin: 0;
      padding: 0;
      list-style: none;

      li{
        margin-bottom: 3px;
      }

    }
  }

  .footer-logo-holder{
    width: 19%;
    float: left;
    padding: 7px 0 0 2px;
  }

  .footer-logo{
    float: left;
    width: 81px;
    height: 81px;
    background: url("../images/logo-small.png") no-repeat;
    text-indent: -9999px;
  }

  .column{
    float: left;
  }

  .contact-info {
    padding-left: 2px;

    span {
      display: inline-block;
      margin-bottom: 4px;
    }
  }

  .bottom-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 19px 40px 22px;
    font-size: 12px;
    font-family: 'Arial', sans-serif;
    border-top: 1px solid #4f575d;
    overflow: hidden;
  }

  p {
    margin: 0;
    display: inline-block;
    font-size: 12px;
    // letter-spacing: -0.2px;
    vertical-align: middle;
  }
  .footer-nav{
    margin: 0 0 0 -3px;
    padding: 0;
    list-style: none;
    display: inline-block;
    vertical-align: middle;

    li{
      display: inline-block;
      padding: 0 4px 0 10px;
      background: url("../images/separator.png") no-repeat 0 50%;

      &:first-child{
        background: none;
      }
    }

    a{
      color: #ddd;
      text-decoration: underline;

      &:hover{
        text-decoration: none;
      }
    }
  }
  .social-networks{
    position: relative;
    display: inline-block;
    margin: 0 0 0 37px;
    padding: 2px 0 0;
    list-style: none;
    vertical-align: middle;

    li{
      float: left;
      margin: 0 5px;

      a{
        text-indent: -9999px;
        overflow: hidden;
        width: 26px;
        height: 25px;
        display: block;

        &.twitter{
          background: url("../images/ico-twitter.png") no-repeat;
        }

        &.linkedin{
          background: url("../images/ico-linkedin.png") no-repeat;
        }

        &.youtube{
          background: url("../images/ico-youtube.png") no-repeat;
        }
      }
    }
  }
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $overlay-index;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
}

.link-holder{
  text-align: center;
}

.headline{
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 0 91px;
  .single &,
  .page-template-default & {
    background-color: #002fd3;
  }

  .holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 26px 0 24px;
  }

  h1{
    width: 100%;
    font-size: 38px;
    line-height: 52px;
    font-weight: 300;
    // margin: 26px 0 0;
    margin: 0;
  }

  .btn {
    margin-top: 30px;
  }

  .headline-bottom{
    // display: none;
    content: "";
    width: 100%;
    height: 100%;
    background: #fff;
    clip-path: ellipse(72% 49.5% at 50% 50%);
    position: absolute;
    bottom: -17%;
    left: 0;
    z-index: -1;
    .single &,
    .page-template-default & {
      background-color: #002fd3;
    }
  }
}

.gray{
  background: #f5f5f5;
  padding: 65px 0;
}

.content-block{
  // margin: 0 0 101px;
  padding: 60px 0;
  overflow: hidden;
  &.image-left {
    .holder {
      flex-direction: row-reverse;
    }
  }

  &.align-top {
    .holder {
      align-items: flex-start;
    }

    .text-holder {
      padding-top: 40px;
    }
  }

  &.align-horizontal {
    .holder {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .text-holder {
      width: 70%;
    }
  }

  .holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-holder{
    width: 50%;

    h2{
      margin: 0 0 21px;
      font-size: 24px;
      line-height: 1.475;
      letter-spacing: -0.45px;
    }

    p {
      margin: 0;
      letter-spacing: -0.75px;
    }
  }

  .image-holder{
    text-align: center;
  }

  .icon-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    border-radius: 2px;
  }
}

.icons-list-holder{
  // margin: 0 0 60px;
  padding: 40px 0 60px;

  h2 {
    margin: 0 0 53px;
    line-height: 1.475;
  }

  .btn-more {
    font-size: 12px;
    font-weight: 500;
  }
}

.icon-items-list{
  margin: 0 0 0 -45px;
  padding: 0 0 0 20px;
  list-style: none;
  word-spacing: -3px;
  letter-spacing: -3px;
  font-size: 14px;
  &.three-column {
    >li {
      width: 33%;
      margin-right: 0;
    }
  }
  &.one-column {
    >li {
      width: 100%;
      margin-right: 0;
    }
  }

  li{
    word-spacing: 0;
    letter-spacing: 0;
    display: inline-block;
    vertical-align: top;
    width: 48%;
    margin-right: 4%;
    padding: 0 0 34px 96px;
    ul {
      margin: 0;
      padding: 0;
      li {
        position: relative;
        width: 100%;
        padding: 0 0 0 15px;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 7px;
          height: 7px;
          margin-top: -4px;
          border-radius: 50%;
          background-color: #505050;
        }
      }
    }

    &:nth-child(n){
      .icon-holder{
        background: #3cbea7;
      }
    }

    &:nth-child(2n){
      margin-right: 0;
      .icon-holder{
        background: #66baff;
        // background: #eb0094;
      }
    }

    &:nth-child(2n+1){
      .icon-holder{
        background: #eb0094;
        // background: #83c31d;
      }
    }
    &:nth-child(4n){
      .icon-holder{
        background: #ff7d00;
        // background: #66baff;
      }
    }
    &:nth-child(4n+1){
      .icon-holder{
        background: #83c31d;
        // background: #ff7d00;
      }
    }
    &:nth-child(6n){
      .icon-holder{
        background: #9a52e0;
      }
    }
    &:nth-child(6n+1){
      .icon-holder{
        background: #3cbea7;
      }
    }
  }

  .icon-holder{
    width: 37px;
    height: 37px;
    border-radius: 2px;
    position: relative;
    float: left;
    margin: 8px 0 0 -71px;
    background: #3cbea7;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      max-width: 100%;
      min-width: 18px;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 3px;
  }
  
  p {
    margin: 0 0 5px;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: -0.6px;
  }
}


.case-study {
  &-block{
    margin-bottom: 112px;
    // padding: 66px 0;
    color: #fff;
  }

  &-description {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    padding: 32px 70px 32px 63px;

    blockquote {
      width: 60%;
      margin: 0 0 0 63px;
    }

    q {
      display: block;
      margin-bottom: 10px;
      font-weight: 300;
      font-style: italic;
    }

    cite {
      font-weight: bold;
      font-style: normal;
    }

    .btn {
      margin-left: auto;
      padding: 0 23px;
      font-size: 14px;
      line-height: 42px;
      text-transform: capitalize;
      white-space: nowrap;
      border: 1px solid #fff;
      background-color: transparent;
    }
  }
}


.pricing {
  text-align: center;

  ul {
    display: flex;
    justify-content: space-between;
    max-width: 831px;
    width: 100%;
    margin: 0 auto 50px;
    padding: 0;
    list-style: none;
  }

  li {
    max-width: 261px;
    width: 100%;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 5px 5px 0 0;

    p {
      margin: 0;
    }
  }

  .text-holder {
    padding: 20px 15px 30px;
  }

  &-info {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #878787;

    p {
      margin: 0;
    }

    img {
      margin-right: 30px;
      padding-bottom: 4px;
    }
  }
}

.color-border {
  height: 10px;
  border-bottom: 1px solid #ddd;
  border-radius: 5px 5px 0 0;
}


.questions {
  h2 {
    text-align: center;
  }

  ul {
    column-count: 2;
    max-width: 975px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    list-style: none;
  }

  li {
    position: relative;
    padding-left: 15px;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 7px;
      height: 7px;
      margin-top: -4px;
      border-radius: 50%;
      background-color: #66baff;
    }
  }

  p {
    display: inline-block;
    margin: 0;
  }

  mark {
    font-weight: bold;
    color: $mark-color;
    background-color: transparent;
  }

}



.filter-logos {

  .integrations-logos {
    
    li {
      width: calc(100% / 6 - 5px);
    }

    img {
      // width: 50%;
    }
  }
}

.filter-btns {
  display: flex;
  justify-content: space-between;
  max-width: 443px;
  width: 100%;
  margin: 0 auto 50px;
  text-align: center;

  div {
    width: 32%;
  }

  .is-checked {
    button {
      color: $blue;
      border-color: $blue;
    }
  }

  button {
    width: 100%;
    padding: 10px 5px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    cursor: pointer;
    border-bottom: 10px solid #ddd;
    transition: color 0.2s, border-color 0.2s
  }
}

.related-logos {
  .integrations-logos {
    li {
      width: calc(100% / 6 - 5px);
    }
  }
}


.page-template-default {
  .content-block {
    .holder {
      align-items: flex-start;
      text-align: left;
    }

    .text-holder {
      width: 100%;
    }

    h1 {
      margin: 0 auto;
      text-align: center;
    }
  }
}


.team {
  text-align: center;

  h2 {
    margin: 0;
  }

  p {
    margin: 0 0 100px;
  }



  &-group {
    // text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  &-item {
    // display: inline-block;
    float: left;
    width: 23.298%;
    margin-right: 25px;
    margin-bottom: 26px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    &:nth-child(4n),
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      .team-hover {
        opacity: 0.9;
        pointer-events: auto;
      }
    }
  }

  &-logo {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 260px;
    background-color: #a8d7fd;
  }

  &-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $blue;
    background-image: url('../images/linkedin.svg');
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s;
  }

  &-descr {
    padding: 20px 10px 10px;
    border-top: 1px solid #ddd;

    strong,
    span {
      line-height: 1;
    }

    strong {
      display: block;
      margin-bottom: 5px;
      color: #1b1b1b;
    }

    span {
      color: $primary-text-color;
    }
  }

}


.holder {
  >.icon-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    margin: 0 auto;
    border-radius: 2px;
  }
}


.jobs {
  padding: 120px 0;
  background-color: #f5f5f5;
  .holder {
    max-width: 735px;
    margin: 0 auto;
  }

  &-list {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      background-color: #fff;
      & + li {
        margin-top: 5px;
      }
    }

    .open {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 49px;
      padding: 0 15px;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid transparent;
      cursor: pointer;
    }
  }

  h2 {
    text-align: center;
  }
}


.news {
  margin-top: -25px;
  padding: 0 0 200px;

  &-page {
    background-color: #f5f5f5;
  }

  .resources-blocks-holder {
    max-width: 942px;
    margin: 0 auto;
  }

  .resources-block {
    margin-right: 0;
    margin-bottom: 40px;

    .image-holder {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: auto;
    }
  }
}


.load-more {
  display: block;
  width: 172px;
  height: 46px;
  margin: 30px auto;
  font-size: 14px;
  line-height: 44px;
  text-transform: uppercase;
  text-align: center; 
  color: $blue;
  border: 2px solid;
  border-radius: 25px;
  background-color: transparent;
}


.post {
  &-media {
    .youtube-player {
      position: relative;
      height: 0;
      padding-bottom: 64.205%;
      background-color: #1a1a1a;
    }
    .youtube-player iframe,
    .youtube-player img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: transparent;
    }

    .youtube-player .play {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      height: 72px;
      width: 72px;
      margin-left: -36px;
      margin-top: -36px;
      background-image: url('../images/video_play_icon_big.svg');
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
    }
  }

  &-title {
    margin-bottom: 50px;
    text-align: center;

    span {
      text-transform: uppercase;
    }

    h1 {
      margin: 20px 0 0;
      line-height: 1.475;
    }

    p {
      margin: 20px 0 0;
    }

    .btn {
      margin-top: 20px;
    }
  }

  .holder {
    max-width: 785px;
  }

  p {
    a {
      color: $primary-text-color;
    }
  }
}


.resources {
  &-page {
    background-color: #f5f5f5;
    .resources {
      &-section {
        margin-bottom: 200px;
        padding-bottom: 0;
        .load-more {
          margin-bottom: 0;
        }
      }

      &-block {
        margin-bottom: 40px;
      }
    }
  }

  &-banner {
    display: block;
    margin: 10px 0 50px;
    text-align: center;
    a {
      display: block;
    }
  }
}


.filter-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;

  div {
    max-width: 294px;
    width: 100%;
    height: 40px;
  }

  select,
  input {
    max-width: 100%;
    width: 100%;
    height: 100%;
    font-size: 14px;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
  }

  select {
    padding-left: 15px;
    text-transform: capitalize;
    color: #404040;
    background-image: url('../images/arrow_small.svg');
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 14px 9px;
    appearance: none;
    &:ms-expand {
      display: none;
    }
  }

  input {
    padding-left: 35px;
    background-image: url('../images/search_field.svg');
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 16px;
    @include placeholder {
      font-size: 14px;
      text-transform: none;
      color: #c7c7c7;
    }
  }
}


.participent {
  padding: 50px 0;
  &-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -40px;
  }

  &-item {
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 40px;
    &.vertical {
      flex-direction: column;
      text-align: center;
    }

    span {
      display: block;
    }
  }

  &-logo {
    img {
      border-radius: 50%;
    }
  }

  &-txt {
    margin-left: 30px;
    padding-right: 30px;
  }
}

.post-review {
  blockquote {
    font-weight: bold;
    text-align: center;
  }

  .participent-item {
    margin: 0 auto;
  }
}


.result {
  text-align: center;
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-item {
    width: 32%;
    margin-bottom: 30px;
    span {
      display: block;
      line-height: 1.475;
    }
  }

  .result-media {
    margin-bottom: 20px;
  }
}