@media screen and (min-width: 1441px) {
  .top-section {
    &.horizontal-alignment {
      .holder {
        margin-right: 100px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {

  #main-nav > ul > li {
    padding: 0 5px;
  }

  .icon-blocks-holder {
    .blocks-wrap {
      max-width: 546px;
      width: 100%;
      margin: 0 auto;
    }

    .icon-block {
      width: 50%;
    }
  }

}


@media screen and (max-width: 768px) {

  .mobile-hidden {
    display: none !important;
  }

  #header {
    // z-index: ;
    .holder {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 21px 30px;
    }
    // &.sub-menu-opened {
    //   background-color: transparent;
    //   #main-nav > ul > li > a {
    //     color: #fff;
    //   }
    //   .side-nav ul li.get-demo a {
    //     background-color: transparent;
    //   }
    //   .logo {
    //     background: url('../images/logo.png');
    //   }
    // }
  }

  .logo {
    margin: 0;
  }

  .side-nav {
    float: none;
    flex-grow: 1;
    margin-right: 19px;
    ul {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      li {
        margin: 0;
      }
    }

    li {
      display: none;
      &.get-demo {
        display: list-item;
      }
    }
    
  }

  #main-nav {
    display: flex;
    &.visible-menu {
      ul {
        display: block;
      }
    }
    .mobile-toggle {
      display: inline-block;
    }

    >ul {
      display: none;
      position: absolute;
      top: 83px;
      left: 0;
      width: 100vw;
      padding: 0;
      text-align: left;
      background-color: $mobile-menu;
      >li {
        position: relative;
        display: block;
        padding: 0;
        border-bottom: 1px solid #1e2930;
        border-bottom: 1px solid lighten($mobile-menu, 8%);
        &.active {
          >a {
            border-bottom: none;
            &::after {
              transform: rotate(-180deg) translateY(5px);
            }
          }
        }
        >a {
          position: relative;
          display: block;
          padding: 15px 30px;
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 20px;
            width: 14px;
            height: 9px;
            background-image: url('../images/chevron.svg');
            background-repeat: no-repeat;
            transition: transform .2s;
            pointer-events: none;
          }
          &:hover {
            border-bottom: none;
          }
        }
      }
    }

    .drop-down {
      position: static;
      padding-top: 0;
      .holder {
        display: flex;
        flex-direction: column;
      }
      .drop-info-block {
        float: none;
        width: 100%;
        margin: 0 0 30px;
        padding: 0;
        .title,
        p {
          display: none;
        }
      }
      .columns {
        width: 100%;
      }
      .column {
        width: 50%;
      }
      .banner {
        display: none;
      }
    }
  }


}