$primary-font-family: 'Montserrat', Arial, sans-serif;
$primary-text-color: #505050;

$primary-link-color: #0577e2;
$blue: #006ce5;
$light-blue: #b3d3f7;
$navy-blue: #0f2942;
$mark-color: #83c31d;
$hover-link: #1a86ff;
$active-link: #0039b2;
$mobile-menu: #121d25;

$header-index: 53;
$bx-index: 51;
$overlay-index: 52;